<template>
  <div class="termgenerator">
    <m-frame
      :routeList="menuList"
      alive-include="Home"
      title="以图搜图"
      deletable
      deleteApi="/imagesearch-uninstall-removeApp.html"
      @delete="deleteApp"
    />
  </div>
</template>

<script>
export default {
  setup() {
    const menuList = [
      {
        path: '',
        title: '操作指南',
      },
      {
        path: 'basic-setting',
        title: '基础设置',
      },
      {
        path: 'vision-setting',
        title: 'google vision 设置',
      },
      {
        path: 'goods-list',
        title: '商品列表',
      },
      {
        path: 'search-history',
        title: '搜索历史',
      },
      {
        path: 'data-analysis',
        title: '数据统计',
      },
    ];

    const deleteApp = async () => {};
    return {
      menuList,
      deleteApp,
    };
  },
};
</script>

<style lang="scss"></style>
